import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { Auth } from '../ts/Auth';

import '../index.css';

export const RootPage: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if(Auth.is_login) {
      navigate('/drone/list');
    } else {
      navigate('/login');
    }
  });
  
  return (
    <>
    </>
  );
}
