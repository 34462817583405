import React from 'react';
import '../ts/Utility';
import { fetch_ex } from '../ts/Utility';
import {errorMessage, infoMessage, questionMessage} from '../ts/Message';
import { DialogResult, ModalComponent, ModalComponentProps } from '../components/ModalComponent'

export const PasswordChangeComponent: React.FC = () => {

    const passwordNow = React.useRef<HTMLInputElement>(null);
    const passwordNew = React.useRef<HTMLInputElement>(null);
    const passwordConfirm = React.useRef<HTMLInputElement>(null);

    const [message , setMessage ] = React.useState('');

    const [processing, setProcessing] = React.useState(false);

    const [modalConfig, setModalConfig] = React.useState<ModalComponentProps | undefined>();
    const modal_dialog = async (title: string, message: string, type?:number) => {
        return await new Promise<number>((resolve) => {
            setModalConfig({
                onClose: (res) => {
                    setModalConfig(undefined);
                    resolve(res);
                },
                title: title,
                message: message,
                type: type
           })
         });
    };

    function passwordTouroku() {
        // 入力チェック呼び出し
        let checkResult = InputCheck();
        if (!checkResult) {return false;}

        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0001).then(result => {
            if(result != DialogResult.Yes) {
                return;
            }

            setProcessing(true);

            // API呼び出し
            const api: string = '/auth/password';
            const method = 'PUT';
            const param = {'old_password': passwordNow.current!.value, 'new_password' : passwordNew.current!.value};
            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);

                if (data.status === 200) {
                    // ステータス：200（ログイン成功）の場合
                    setMessage('パスワード' + infoMessage.I0003);
                } else if (data.status === 401) {
                    // ステータス：401（旧パスワード不一致）の場合
                    setMessage(errorMessage.E0003);
                } else if (data.status === 402) {
                    // ステータス：402（パスワードポリシー非準拠）の場合
                    setMessage(errorMessage.E0004);
                } else {
                    setMessage(errorMessage.E0018);
                }
            });

        });
    }

    function InputCheck() {
        let msg: string = '';
        let valid = true;

        if (!passwordNow.current!.value) {
            msg += '現在のパスワード' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (!passwordNew.current!.value) {
            msg += '新しいパスワード' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (!passwordConfirm.current!.value) {
            msg += 'パスワード（確認）' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        } 
        if (passwordNew.current!.value !== passwordConfirm.current!.value) {
            msg += errorMessage.E0007;
            msg += '\n';
            valid = false; 
        }

        msg.trimEnd();
        setMessage(msg);
        return valid;
    }
        
    return (
        <>
            <main className='password-change'>
                <p className='h5 fw-bold mb-4'>パスワード変更</p>
                <hr/>
                <label className='required'>＊は必須項目です</label>
                <fieldset>
                    <p>
                        <label>現在のパスワード</label><label className='required'>＊</label>
                        <input className='form-control' ref={passwordNow} type='password'/>
                    </p>
                    <p>
                        <label>新しいパスワード</label><label className='required'>＊</label>
                        <input className='form-control' ref={passwordNew} type='password'/>
                    </p>
                    <p>
                        <label>パスワード（確認）</label><label className='required'>＊</label>
                        <input className='form-control' ref={passwordConfirm} type='password'/>
                    </p>
                    <hr/>
                    <button className='btn btn-accept' onClick={passwordTouroku} disabled={processing}>登　録</button>
                    <p className='error-message'>{message}</p>
                </fieldset>

                {modalConfig && <ModalComponent {...modalConfig} />}

            </main>
        </>
    );
}
