import React from 'react';
import { useNavigate } from 'react-router-dom'
import { fetch_ex, parseISO8601, trim_cst } from '../ts/Utility';
import { errorMessage, infoMessage} from '../ts/Message';
import { Auth } from '../ts/Auth';

import img_search from '../assets/images/search.svg';
import img_new from '../assets/images/new.svg';

export const CompanyListComponent: React.FC = () => {
    
    const navigate = useNavigate();

    const [company_id, setCompanyId] = React.useState('');
    const [company_name, setCompanyName] = React.useState('');

    const [message , setMessage ] = React.useState('');
    const [prevSortKey, setPrevSortKey ] = React.useState('');
    const [totalKensu , setTotalKensu ] = React.useState(Number);
    const [fromKensu , setFromKensu ] = React.useState(Number);
    const [toKensu , setToKensu ] = React.useState(Number);
    
    const [rowData , setData ] = React.useState({ data:[]});
    const [pagenation , setPagenation ] = React.useState({ current: 1, pages: [1], max_page: 1 });
    const [tableDisplayFlg , setTableDisplayFlg ] = React.useState(false);

    const [processing, setProcessing] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => { 
        if(Auth.is_admin === false) {
            navigate('/');
            return;
        }

        if(Auth.company_list_query != null) {
            if(Auth.company_list_query.company_id) {
                setCompanyId(Auth.company_list_query.company_id);
            }
            if(Auth.company_list_query.company_name) {
                setCompanyName(Auth.company_list_query.company_name);
            }
        }
        setPrevSortKey('company_id');
        companyListSearch();
    }, []);

    const pageSize = 20;

    const handle_company_id_change = (event:any) => {
        setCompanyId(event.target.value);
    };

    const handle_company_name_change = (event:any) => {
        setCompanyName(event.target.value);
    };

    const default_query = {
        'page' : 1,
        'page_size' : pageSize,
        'sort_key' : 'company_id', 
        'sort_order' : 'ASC'
    };

    const set_query = (base_query: any, key: any, value: any) => {
        if(value && value != null) {
            base_query[key] = value;
        } else {
            delete base_query[key];
        }
    }

    const rebuild_query = (base_query: any) => {
        for(let key in base_query) {
            if(key.startsWith('_')) {
                delete base_query[key];
            }
        }
    }

    const handle_search = () => {
        companyListSearch({ 
            'company_id' : company_id !== '' ? company_id : null,
            'company_name': company_name !== '' ? company_name : null,
            'page' : 1 
        });
    };

    function companyListSearch(append_query?: any) {
        setProcessing(true);

        // API呼び出し
        const api: string = '/company/search';
        const method: string = 'POST';

        let query:any = Auth.company_list_query === null ? default_query : Auth.company_list_query;

        if(append_query) {
            for(let key in append_query) {
                set_query(query, key, append_query[key]);
            }
        }

        Auth.company_list_query = query;

        rebuild_query(query);
        fetch_ex(api , method, query, true).then(data => {
            setProcessing(false);
            setIsLoading(false);

            if(data.status !== 200) {
                setMessage(errorMessage.E0018);
                return;
            }

            let rows:any = { };
            rows.data = [];
            for (let i in data.result.company_list) {
                let item = data.result.company_list[i];
                rows.data.push({
                        company_id: item.company_id,
                        company_name: item.company_name,
                        authority: item.authority,
                        is_enabled: item.is_enabled,
                        remarks: item.remarks,
                        created_at: item.created_at,
                        modified_at: item.modified_at,
                      });
            }

            setFromKensu(pageSize * (data.result.page - 1) + 1);
            setToKensu(pageSize * (data.result.page - 1) + data.result.company_list.length);
            setTotalKensu(data.result.count);
            setData(rows);
            let pages: number[] = [];

            const page_span = 2;
            let start_page = data.result.page - page_span;
            let end_page = data.result.page + page_span;
            if(start_page < 1) {
                end_page = end_page - start_page + 1;
                start_page = 1;
            } else if(end_page > data.result.max_page) {
                start_page = start_page + data.result.max_page - end_page;
                end_page = data.result.max_page;
            }
            if(start_page < 1) {
                start_page = 1;
            } else if(end_page > data.result.max_page) {
                end_page = data.result.max_page;
            }
            
            for(let i = start_page; i <= end_page; i++) {
                pages.push(i);
            }

            setPagenation({
                current: data.result.page,
                pages : pages,
                max_page: data.result.max_page,
            });
            
            if (data.result.count === 0) {
                // 検索結果0件
                setTableDisplayFlg(false);
                setMessage(infoMessage.I0006);
            } else {
                setTableDisplayFlg(true);
                setMessage('');
            }
        });

    }

    function toCompanyRegister(){
        // 企業登録画面へ遷移
        navigate('/company/regist');
    }

    const toCompanyEdit = (event:any) => {
        const company_id = event.target.dataset.company;
        navigate('/company/regist?company_id=' + encodeURIComponent(company_id));
        event.preventDefault();
    };

    const set_order = (event:any) => {
        if(prevSortKey === event.target.dataset.sortkey) {
            companyListSearch({'sort_key' : event.target.dataset.sortkey, 'sort_order' : 'DESC', 'page' : 1 });
            setPrevSortKey('');
        } else {
            companyListSearch({'sort_key' : event.target.dataset.sortkey , 'sort_order' : 'ASC', 'page' : 1 });
            setPrevSortKey(event.target.dataset.sortkey);
        }
        event.preventDefault();
    };

    const set_page = (event:any) => {
        companyListSearch({'page' : event.target.dataset.page});
        event.preventDefault();
    };

    const handle_keydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter') {
            handle_search();
        }
    };

    return (
        <>
            <main className='company-list'>
                <label className='h5 fw-bold mb-4'>企業一覧</label>
                <hr/>
                <fieldset className='hstack'>
                    <p className='me-3'>
                        <label>企業ID</label>
                        <input className='form-control' type='text' onKeyDown={handle_keydown} value={company_id} onChange={handle_company_id_change} />
                    </p>
                    <p className='me-3'>
                        <label>企業名（部分一致）</label>
                        <input className='form-control' type='text' onKeyDown={handle_keydown} value={company_name} onChange={handle_company_name_change}/>
                    </p>
                    <button className='btn btn-search' onClick={handle_search} disabled={processing}><img src={img_search} alt='' />検　索</button>
                </fieldset>

                <hr/>

                <div className='hstack mb-3 align-items-end'>
                    <p className='error-message'>{message}</p>
                    <div className={tableDisplayFlg ? '' : 'd-none'}>全 {totalKensu} 件中　{fromKensu} 件 ～ {toKensu} 件 を表示</div>
                    <button className='ms-auto btn btn-accept' onClick={toCompanyRegister}><img src={img_new} alt='' />新規登録</button>
                </div>

                <table className={tableDisplayFlg ? 'table radius-table' : 'd-none'}>
                    <thead>
                        <tr>
                        <th><a data-sortkey='company_id' href='.' onClick={set_order}>企業ID</a></th>
                        <th><a data-sortkey='company_name' href='.' onClick={set_order}>企業名</a></th>
                        <th className='text-center'><a data-sortkey='authority' href='.' onClick={set_order}>管理者権限</a></th>
                        <th className='text-center'><a data-sortkey='is_enabled' href='.' onClick={set_order}>使用区分</a></th>
                        <th><a data-sortkey='remarks' href='.' onClick={set_order}>備考</a></th>
                        <th><a data-sortkey='created_at' href='.' onClick={set_order}>登録日時</a></th>
                        <th><a data-sortkey='modified_at' href='.' onClick={set_order}>更新日時</a></th>
                        </tr>
                    </thead>
                    <tbody className='detail-body'>
                        {rowData.data.map((row: any) => {
                            return (
                                <tr>
                                <td><a className='fw-bold' href='.' data-company={row.company_id} onClick={toCompanyEdit}>{row.company_id}</a></td>
                                <td><label>{row.company_name}</label></td>
                                <td className='text-center'><label>{row.authority == Auth.ADMIN ? '◯' : ''}</label></td>
                                <td className='text-center'><label>{row.is_enabled == Auth.ENABLE ? '使用' : '停止'}</label></td>
                                <td><label>{trim_cst(row.remarks)}</label></td>
                                <td><label>{parseISO8601(row.created_at)}</label></td>
                                <td><label>{parseISO8601(row.modified_at)}</label></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <nav aria-label='...' className={ pagenation.max_page <= 1 ? 'd-none' :'' }>
                    <ul className='pagination'>
                        <li className='page-item'>
                            <a className='page-link' href='.' data-page={1} onClick={set_page}>≪</a>
                        </li>

                        {pagenation.pages.map((page: number) => {
                            if(page == pagenation.current) {
                                return (
                                    <li className='page-item active' aria-current='page'>
                                        <span className='page-link'>{page}</span>
                                    </li>
                                );
    
                            } else {
                                return (
                                    <li className='page-item'><a className='page-link' href='.' data-page={page} onClick={set_page}>{page}</a></li>
                                );
                            }
                        })}

                        <li className='page-item'>
                            <a className='page-link' href='.' data-page={pagenation.max_page} onClick={set_page}>≫</a>
                        </li>
                    </ul>
                </nav>

                <div className={ 'd-flex align-items-center justify-content-center' + (isLoading ? '' : ' d-none') }>
                    <div className='d-inline-block h3'>
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>ロード中...</span>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}
