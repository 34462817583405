import * as React from 'react';
import { fetch_ex } from '../ts/Utility';
import '../index.css';

export const LogoutPage: React.FC = () => {
  React.useEffect(()=>{
    // API呼び出し
    const api: string = '/auth/logout';
    const method = 'POST';
    const param = {};
    fetch_ex(api , method, param, true).then(data => {
        // ログイン画面へ遷移
        window.location.assign('/login');
     });

    sessionStorage.clear();
  }, []);

  return (
    <>
        <div className='logout d-flex align-items-center justify-content-center'>
            <div className='d-inline-block h3'>
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>ログアウト...</span>
                </div>
            </div>
        </div>
    </>
  );
}
