import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './ts/reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RootPage } from './pages/RootPage';
import { LoginPage } from './pages/LoginPage';
import { PasswordChangePage } from './pages/PasswordChangePage';
import { CompanyListPage } from './pages/CompanyListPage';
import { CompanyRegisterPage } from './pages/CompanyRegisterPage';
import { GatewayListPage } from './pages/GatewayListPage';
import { GatewayRegisterPage } from './pages/GatewayRegisterPage';
import { DroneListPage } from './pages/DroneListPage';
import { DroneRegisterPage } from './pages/DroneRegisterPage';
import { FlightHistoryDownLoadPage } from './pages/FlightHistoryDownLoadPage';
import { CompanyBulkRegisterPage } from './pages/CompanyBulkRegisterPage';
import { GatewayBulkRegisterPage } from './pages/GatewayBulkRegisterPage';
import { DroneBulkRegisterPage } from './pages/DroneBulkRegisterPage';
import { LogoutPage } from './pages/LogoutPage';

const bootstrap = require('bootstrap');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<RootPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/password/change' element={<PasswordChangePage />} />
        <Route path='/company/list' element={<CompanyListPage />} />
        <Route path='/company/regist' element={<CompanyRegisterPage />} />
        <Route path='/gateway/list' element={<GatewayListPage />} />
        <Route path='/gateway/regist' element={<GatewayRegisterPage />} />
        <Route path='/drone/list' element={<DroneListPage />} />
        <Route path='/drone/regist' element={<DroneRegisterPage />} />
        <Route path='/history/download' element={<FlightHistoryDownLoadPage />} />
        <Route path='/company/csv' element={<CompanyBulkRegisterPage />} />
        <Route path='/gateway/csv' element={<GatewayBulkRegisterPage />} />
        <Route path='/drone/csv' element={<DroneBulkRegisterPage />} />
        <Route path='/logout' element={<LogoutPage />} />
        <Route path='*' element='not found' />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
