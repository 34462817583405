import React from 'react';

export const LoginPageFooterComponent: React.FC = () => {
  return (
    <>
      <label>© 2022 Kyodo Printing Co.,Ltd.</label>
    </>
  );
}

