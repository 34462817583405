import React from 'react';
import '../ts/Utility';
import { add_comma, fetch_ex, formatDate, getToday, getTomorrow, getYesterday, toISO8601 } from '../ts/Utility';
import {errorMessage} from '../ts/Message';
import { Auth } from '../ts/Auth';
import { DialogResult, DialogType, ModalComponent, ModalComponentProps } from './ModalComponent';

import img_download from '../assets/images/download.svg';

export const FlightHistoryDownLoadComponent: React.FC = () => {

    const [message, setMessage] = React.useState<string>('');
    const [company_name, setCompanyName] = React.useState<string>('');
    const [company_id, setCompanyId] = React.useState<string>('');
    const [company_list, setCompanyList] = React.useState<any[]>([]);

    const [gateway_ids, setGatewayIds] = React.useState<string[]>([]);
    const [gateway_list, setGatewayList] = React.useState<string[]>([]);

    const [date_start, setDateStart] = React.useState<string>(formatDate(getToday(), 'yyyy-MM-dd'));
    const [date_end, setDateEnd] = React.useState<string>(formatDate(getTomorrow(), 'yyyy-MM-dd'));
    const [time_start, setTimeStart] = React.useState<string>('00:00');
    const [time_end, setTimeEnd] = React.useState<string>('00:00');

    const [drone_type, setDroneType] = React.useState<string>('');
    const [has_time_condition, setHasTimeCondition] = React.useState<string>('1');

    const download_anchor = React.useRef<HTMLAnchorElement>(null);
    const [download_url , setDownloadUrl] = React.useState('');

    const [processing, setProcessing] = React.useState(false);

    const [modalConfig, setModalConfig] = React.useState<ModalComponentProps | undefined>();
    const modal_dialog = async (title: string, message: string, type?:number) => {
        return await new Promise<number>((resolve) => {
            setModalConfig({
                onClose: (res) => {
                    setModalConfig(undefined);
                    resolve(res);
                },
                title: title,
                message: message,
                type: type
           })
         });
    };

    React.useEffect(() => {
        init_company_list();
        if(Auth.is_admin) {
            init_gateway_list();
        } else {
            init_gateway_list(Auth.company_id);
        }
    }, []);

    const init_company_list = () => {
        if(Auth.is_admin) {
            const api: string = '/company/list';
            const method: string = 'GET';
            let query:any = {};
    
            fetch_ex(api , method, query, true).then(data => {
                if(data.status !== 200) {
                    setMessage(errorMessage.E0018);
                    return;
                }
                setCompanyList(data.result.company_list);
            });
        } else {
            setCompanyList([{'company_name':Auth.company_name, 'company_id' : Auth.company_id}]);
            setCompanyName(Auth.company_name!);
            setCompanyId(Auth.company_id!);
        }
    };

    const init_gateway_list = (company?:any) => {
        const api: string = '/gateway/list';
        const method: string = 'GET';
        let query:any = {};

        if(!!company && company != '') {
            query.company_id = company;
        }

        setGatewayIds([]);

        fetch_ex(api , method, query, true).then(data => {
            if(data.status !== 200) {
                setMessage(errorMessage.E0018);
                return;
            }

            const gateway_set:Set<string> = new Set(data.result.gateway_list);
            let gateway_list = Array.from(gateway_set);
            gateway_list.sort();
            setGatewayList(gateway_list);
        });
    };

    const handle_dropdown_company_click = (event:any) => {
        setCompanyId(event.target.dataset.value);
        setCompanyName(event.target.dataset.text);
        init_gateway_list(event.target.dataset.value);
        event.preventDefault();
    };

    const handle_dropdown_gateway_change = (event:any) => {
        let list = [];
        if(event.target.checked) {
            list = gateway_ids.concat([event.target.value]);
        } else {
            list = gateway_ids.filter(v => v != event.target.value);
        }
        list.sort();
        setGatewayIds(list);
    };

    const handle_dropdown_gateway_click = (event:any) => {
        event.stopPropagation();
    };

    const handle_drone_type_change = (event:any) => {
        setDroneType(event.target.value);
    };

    const handle_has_time_condition_change = (event:any) => {
        setHasTimeCondition(event.target.value);
    };

    const handle_date_start_change = (event:any) => {
        setDateStart(event.target.value);
    };
    const handle_date_end_change = (event:any) => {
        setDateEnd(event.target.value);
    };

    const handle_dropdown_time_start_click = (event:any) => {
        setTimeStart(event.target.dataset.value);
        event.preventDefault();
    };
    const handle_dropdown_time_end_click = (event:any) => {
        setTimeEnd(event.target.dataset.value);
        event.preventDefault();
    };

    const handle_download = () => {
        const checkResult = validate_input();
        if (!checkResult) {return false;}

        setProcessing(true);

        const api: string = '/flight_history/csv/search';
        const method: string = 'POST';
        let query:any = {};

        if(!!company_id && company_id != '') {
            query.company_id = company_id;
        }

        if(gateway_ids.length > 0) {
            query.gateway_ids = gateway_ids.join(',');
        }

        query.drone_type = Number(drone_type);

        if(has_time_condition == '1') {
            if(date_start != '') {
                query.start_date = toISO8601(new Date(date_start + ' ' + time_start));
            }
            if(date_end != '') {
                query.end_date = toISO8601(new Date(date_end + ' ' + time_end));
            }
        }

        fetch_ex(api , method, query, true).then(data => {
            if(data.timeout) {
                setMessage(errorMessage.E0013);
            } else if(data.status === 200) {
                setDownloadUrl(data.result.presigned_url);
                download_anchor.current!.click();
            } else if(data.status === 401) {
                setMessage(errorMessage.E0009);
            } else if(data.status === 402) {
                setMessage('');

                modal_dialog('エラー', errorMessage.E0014.replace('{0}', add_comma(data.error.additional)), DialogType.Error);
            } else {
                setMessage(errorMessage.E0018);
            }
            setProcessing(false);
        });
    };

    const validate_input = () => {
        let msg: string = '';
        let valid: boolean = true;

        if(drone_type == '') {
            msg += 'ドローン種別' + errorMessage.E0001;
            msg += '\n';
            valid = false; 
        }

        if(has_time_condition == '1') {

            if(date_start == ''　&& date_end == '') {
                msg += '飛行日時' + errorMessage.E0001;
                msg += '\n';
                valid = false; 
            } 

            let today = getToday();
            if(today.getMonth() == 2 && today.getDate() == 29) {
                today = getYesterday();
            }
            today.setFullYear(today.getFullYear() - 1);

            if(date_start != '') {
                if (date_start < formatDate(today, 'yyyy-MM-dd')) {
                    msg += errorMessage.E0010;
                    msg += '\n';
                    valid = false; 
                }
            }

            if(date_start != ''　&& date_end != '') {
                if (date_start + time_start >= date_end + time_end) {
                    msg += errorMessage.E0011;
                    msg += '\n';
                    valid = false; 
                }
            }
        }

        msg.trimEnd();
        setMessage(msg);
        return valid;
    };

    return (
        <>
            <main className={ 'flight-history-download' + Auth.class_admin }>
                <label className='h5 fw-bold mb-4'>飛行ログダウンロード</label>
                <hr/>

                <div className='my-4'>
                    <label>企業名</label>
                    <div className='dropdown dropdown-company'>
                        <button className='btn border dropdown-toggle' type='button' id='dropdownCompany' data-bs-toggle='dropdown' aria-expanded='false' disabled={Auth.is_normal}>
                            <label data-value={company_id}>{company_id == '' ? '' : company_id + '-' + company_name}</label>
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownCompany'>
                            <li><a className='dropdown-item' href='.' onClick={handle_dropdown_company_click} data-value='' data-text=''>&nbsp;</a></li>
                            { company_list.map((item) => {
                                return(
                                    <li><a className='dropdown-item' href='.' onClick={handle_dropdown_company_click} data-value={item.company_id} data-text={item.company_name}>{item.company_id + '-' + item.company_name}</a></li>
                                )
                            }) }
                        </ul>
                    </div>
                </div>

                <div className='my-4'>
                    <label>ゲートウェイID</label>
                    <div className='dropdown dropdown-gateway'>
                        <button className='btn border' type='button' id='dropdownGateway' data-bs-toggle='dropdown' aria-expanded='false' disabled={company_id === ''}>
                            <label>{gateway_ids.join(',')}</label>
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownGateway'>
                            { gateway_list.map((item) => {
                                return(
                                    <li>
                                        <div className='form-check ms-2'>
                                            <label className='form-check-label' onClick={handle_dropdown_gateway_click}><input className='form-check-input' type='checkbox' value={item} onChange={handle_dropdown_gateway_change} checked={gateway_ids.includes(item)} />{item}</label>
                                        </div>
                                    </li>
                                )
                            }) }
                        </ul>
                    </div>
                </div>

                <div className='my-4'>
                    <label>ドローン</label><label className='required me-4'>＊</label>
                    <label className='form-check form-check-inline'>
                        <input className='form-check-input' type='radio' name='drone_type' value='1' checked={ drone_type == '1' }  onChange={handle_drone_type_change}/>許可ドローンのみ
                    </label>
                    <label className='form-check form-check-inline'>
                        <input className='form-check-input' type='radio' name='drone_type' value='0' checked={ drone_type == '0'} onChange={handle_drone_type_change}/>許可ドローン以外
                    </label>
                    <label className='form-check form-check-inline'>
                        <input className='form-check-input' type='radio' name='drone_type' value='-1' checked={ drone_type == '-1'} onChange={handle_drone_type_change}/>全て
                    </label>
                </div>

                <div className='my-4'>
                    <label>飛行日時指定</label><label className='required me-4'>＊</label>
                    <label className='form-check form-check-inline'>
                        <input className='form-check-input' type='radio' name='has_time_condition' value='1' checked={ has_time_condition == '1' }  onChange={handle_has_time_condition_change}/>指定あり
                    </label>
                    <label className='form-check form-check-inline'>
                        <input className='form-check-input' type='radio' name='has_time_condition' value='0' checked={ has_time_condition == '0'} onChange={handle_has_time_condition_change}/>指定なし
                    </label>
                </div>

                <div className='my-4'>
                    <label>飛行日時</label>

                    <div className='hstack' >
                        <input className='form-control date me-2' type='date' readOnly={has_time_condition=='0'} value={date_start} onChange={handle_date_start_change} />

                        <button className='btn border dropdown-toggle time' type='button' id='dropdownTimeStart' data-bs-toggle='dropdown' aria-expanded='false' disabled={has_time_condition=='0'}>
                            <label data-value={time_start}>{time_start}</label>
                        </button>
                        <ul className='dropdown-menu time' aria-labelledby='dropdownTimeStart'>
                            {
                                ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'].map(item => {
                                    return (
                                        <li><a className='dropdown-item' href='.' onClick={handle_dropdown_time_start_click} data-value={item}>{item}</a></li>
                                    );
                                })
                            }
                            
                        </ul>

                        <label className='mx-4'>～</label>

                        <input className='form-control date me-2' type='date' disabled={has_time_condition=='0'} value={date_end} onChange={handle_date_end_change} />

                        <button className='btn border dropdown-toggle time' type='button' id='dropdownTimeEnd' data-bs-toggle='dropdown' aria-expanded='false' disabled={has_time_condition=='0'} >
                            <label data-value={time_end}>{time_end}</label>
                        </button>
                        <ul className='dropdown-menu time' aria-labelledby='dropdownTimeEnd'>
                            {
                                ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'].map(item => {
                                    return (
                                        <li><a className='dropdown-item' href='.' onClick={handle_dropdown_time_end_click} data-value={item}>{item}</a></li>
                                    );
                                })
                            }
                            
                        </ul>

                    </div>

                    <div className='mt-2 mb-5'>
                        <div>※飛行ログの保存期間は１年です。</div>
                        <div>※ダウンロードの上限数は30万件です。</div>
                    </div>
                </div>

                <hr />

                <div className='my-4'>
                    <button className='btn btn-accept' onClick={handle_download} disabled={processing}><img src={img_download} alt='' />ダウンロード</button>
                    <a className='invisible' ref={download_anchor} href={download_url} download={download_url}>download</a>
                </div>
                <div className={ 'h3 ' + (processing ? '' : 'd-none')}>
                    <div className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>処理中...</span>
                    </div>
                </div>

                <div className='error-message'>
                    {message}
                </div>

            </main>

            {modalConfig && <ModalComponent {...modalConfig} />}
        </>
    );
}
