import React from 'react';
import { useNavigate } from 'react-router-dom'
import '../ts/Utility';
import { fetch_ex } from '../ts/Utility';
import {errorMessage, infoMessage, questionMessage} from '../ts/Message';
import { DialogResult, DialogType, ModalComponent, ModalComponentProps } from '../components/ModalComponent'

import { useLocation } from 'react-router-dom';
import { Auth } from '../ts/Auth';


export const CompanyRegisterComponent: React.FC = () => {
    const navigate = useNavigate();

    const [company_id, setCompanyId] = React.useState('');
    const [company_name, setCompanyName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [password_confirm, setpasswordConfirm] = React.useState('');
    const [authority, setAuthority] = React.useState('');
    const [is_enabled, setIsEnabled] = React.useState('');
    const [remarks, setRemarks] = React.useState('');

    const handle_company_id_change = (event:any) => setCompanyId(event.target.value);
    const handle_company_name_change = (event:any) => setCompanyName(event.target.value);
    const handle_password_change = (event:any) => setPassword(event.target.value);
    const handle_password_confirm_change = (event:any) => setpasswordConfirm(event.target.value);
    const handle_authority_change = (event:any) => setAuthority(String(event.target.value));
    const handle_isenabled_change = (event:any) => setIsEnabled(String(event.target.value));
    const handle_remarks_change = (event:any) => setRemarks(event.target.value);

    const [title , setTitle ] = React.useState('');
    const [message , setMessage ] = React.useState('');

    const [isEdit , setIsEdit ] = React.useState(false);

    const searchQuery = useLocation().search;
    const queryParameter = new URLSearchParams(searchQuery);

    const [processing, setProcessing] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [modalConfig, setModalConfig] = React.useState<ModalComponentProps | undefined>();
    const modal_dialog = async (title: string, message: string, type?:number) => {
        return await new Promise<number>((resolve) => {
            setModalConfig({
                onClose: (res) => {
                    setModalConfig(undefined);
                    resolve(res);
                },
                title: title,
                message: message,
                type: type
           })
         });
    };

    React.useEffect(() => { initialize(); }, []);
    const initialize = () => {
        if(Auth.is_admin === false) {
            navigate('/');
            return;
        }
    
        if(queryParameter.has('company_id')) {
            setTitle('企業編集');
            initialize_edit(queryParameter.get('company_id')!);
        } else {
            setTitle('企業新規登録');
            setIsLoading(false);
        }
    };

    const initialize_edit = (company_id: string) => {
        setCompanyId(company_id);
        setIsEdit(true);

        // API呼び出し
        const api: string = '/company';
        const method = 'GET';
        const param = {'company_id': company_id };

        fetch_ex(api , method, param, true).then(data => {
            if (data.status === 200) {
                // ステータス：200（成功）の場合
                setCompanyName(data.result.company_name);
                setAuthority(data.result.authority);
                setIsEnabled(data.result.is_enabled);
                setRemarks(data.result.remarks);

            } else {
                setMessage(errorMessage.E0018);
            }

            setIsLoading(false);
        });
    };


    const handle_back = () => navigate('/company/list');

    const handle_overwrite = () => {
        // 入力チェック呼び出し
        const checkResult = validate_input_overwrite();
        if (!checkResult) {return false;}

        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0004).then(result => {
            if(result !== DialogResult.Yes) {
                return;
            }

            setProcessing(true);
            // API呼び出し
            const api: string = '/company';
            const method = 'PUT';
            let param:any = {
                'company_id': company_id,
                'company_name' : company_name,
                'authority' : authority, 
                'is_enabled' : is_enabled, 
                'remarks' : remarks
            };
            if(password !== '') {
                param.password = password;
            }

            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);

                if (data.status === 200) {
                    // ステータス：200（成功）の場合
                    modal_dialog('完了', '企業情報' + infoMessage.I0004, DialogType.Information).then(result => {
                        navigate('/company/list');
                    });

                } else if (data.status === 401) {
                    // ステータス：401（対象企業無し）の場合
                    console.log('/company PUT -> 401');
                } else if (data.status === 402) {
                    // ステータス：402（パスワードポリシー非準拠）の場合
                    setMessage(errorMessage.E0004);
                } else {
                    setMessage(errorMessage.E0018);
                }
            });
        });
            
    };

    const handle_delete = () => {
        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0005).then(result => {
            if(result !== DialogResult.Yes) {
                return;
            }

            setProcessing(true);

            // API呼び出し
            const api: string = '/company';
            const method = 'DELETE';
            const param = {
                'company_id': company_id
            };
            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);

                if (data.status === 200) {
                    // ステータス：200（成功）の場合
                    modal_dialog('完了', '企業情報' + infoMessage.I0005, DialogType.Information).then(result => {
                        navigate('/company/list');
                    });
                } else {
                    setMessage(errorMessage.E0018);
                }
            });
        });
    };

    const handle_register = () => {
        // 入力チェック呼び出し
        const checkResult = validate_input_regist();
        if (!checkResult) {return false;}

        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0003).then(result => {
            if(result !== DialogResult.Yes) {
                return;
            }

            setProcessing(true);

            // API呼び出し
            const api: string = '/company';
            const method = 'POST';
            const param = {
                'company_id': company_id, 
                'company_name' : company_name, 
                'password' : password, 
                'authority' : authority, 
                'is_enabled' : is_enabled, 
                'remarks' : remarks
            };
            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);

                if (data.status === 200) {
                    // ステータス：200（成功）の場合
                    modal_dialog('完了', '企業情報' + infoMessage.I0003, DialogType.Information).then(result => {
                        navigate('/company/list');
                    });

                } else if (data.status === 401) {
                    // ステータス：401（企業ID重複）の場合
                    setMessage(errorMessage.E0006);
                } else if (data.status === 402) {
                    // ステータス：402（パスワードポリシー非準拠）の場合
                    setMessage(errorMessage.E0004);
                } else {
                    setMessage(errorMessage.E0018);
                }
            });
        });

    }

    const validate_input_regist = () => {
        let msg: string = '';
        let valid = true;

        if (company_id === '') {
            msg += '企業ID' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (company_name === '') {
            msg += '企業名' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (password === '') {
            msg += 'パスワード' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (password_confirm === '') {
            msg += 'パスワード（確認）' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        } 
        if (authority === '') {
            msg += '属性' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        } 
        if (is_enabled === '') {
            msg += '使用区分' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        } 
        if (password !== password_confirm) {
            msg += errorMessage.E0007;
            msg += '\n';
            valid = false; 
        }

        msg.trimEnd();
        setMessage(msg);
        return valid;
    };

    const validate_input_overwrite = () => {
        let msg: string = '';
        let valid = true;

        if (company_name === '') {
            msg += '企業名' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }

        if (password !== '' || password_confirm !== '') {
            if (password != password_confirm) {
                msg += errorMessage.E0007;
                msg += '\n';
                valid = false; 
            }
        }

        msg.trimEnd();
        setMessage(msg);
        return valid;
    };

    return (
        <>
            <main className='company-register'>
                <label className='h5 fw-bold mb-4'>{title}</label>
                <hr/>

                <div className={ 'd-inline-block h3 position-absolute ' + (isLoading ? '' : 'd-none')}>
                    <div className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>ロード中...</span>
                    </div>
                </div>

                <fieldset className={ isLoading ? 'invisible' : 'visible'}> 
                    <label className='required my-2'>＊は必須項目です</label>
                    <p>
                        <label>企業ID</label><label className='required'>＊</label>
                        <input className='form-control' type='text' readOnly={isEdit} value={company_id} onChange={handle_company_id_change}/>
                    </p>
                    <p>
                        <label>企業名</label><label className='required'>＊</label>
                        <input className='form-control' type='text' value={company_name} onChange={handle_company_name_change}/>
                    </p>
                    <p>
                        <label>パスワード</label><label className={ isEdit ? 'invisible' : 'required' }>＊</label>
                        <div>
                            <input className='form-control d-inline' type='password' value={password} onChange={handle_password_change}/>
                            <div className='d-inline-block align-bottom ms-3'>
                                <label className='d-block'>※英字と数字の組合せから成るパスワードを使用してください</label>
                                <label className='d-block'>※パスワードの長さは、最低８文字以上にしてください</label>
                            </div>
                        </div>
                    </p>
                    <p>
                        <label>パスワード（確認）</label><label className={ isEdit ? 'invisible' : 'required' }>＊</label>
                        <input className='form-control' type='password' value={password_confirm} onChange={handle_password_confirm_change}/>
                    </p>
                    <p>
                        <label>属性</label><label className='required me-4'>＊</label>
                        <label className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' name='authority' value='0' checked={ authority == '0' }  onChange={handle_authority_change}/>一般
                        </label>
                        <label className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' name='authority' value='1' checked={ authority == '1'} onChange={handle_authority_change}/>管理者
                         </label>
                    </p>
                    <p>
                        <label>使用区分</label><label className='required me-4'>＊</label>
                        <label className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' name='is_enabled' value='1' checked={ is_enabled == '1'} onChange={handle_isenabled_change}/>使用
                        </label>
                        <label className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' name='is_enabled' value='0' checked={ is_enabled == '0'} onChange={handle_isenabled_change}/>停止
                         </label>
                    </p>
                    <p>
                        <label>備考</label>
                        <textarea className='form-control' value={remarks} onChange={handle_remarks_change} />
                    </p>

                </fieldset>

                <hr />

                <div className='hstack'>
                    <button className={ 'me-4 btn btn-accept' + (isEdit ? ' d-none' : '') } onClick={handle_register} disabled={processing || isLoading}>登　録</button>
                    <button className={ 'me-4 btn btn-accept' + (isEdit ? '' : ' d-none') } onClick={handle_overwrite} disabled={processing || isLoading}>上書き</button>
                    <button className={ 'me-4 btn btn-delete' + (isEdit ? '' : ' d-none') } onClick={handle_delete} disabled={processing || isLoading}>削　除</button>
                    <button className='me-4 btn btn-back' onClick={handle_back} disabled={processing || isLoading}>戻　る</button>
                </div>
                <p className='error-message'>{message}</p>

            </main>

            {modalConfig && <ModalComponent {...modalConfig} />}
        </>
    );
}
