import React from 'react';
import img_logo1 from '../assets/images/logo1.png';

export const LoginPageHeaderComponent: React.FC = () => {
  return (
    <>
      <div className='h-100 d-flex align-items-center'>
        <div className='logo-wrap'>
          <div className='d-flex align-items-center justify-content-center logo'><img src={img_logo1} alt='ロゴ' /></div>
        </div>
      </div>
    </>
  );
}

