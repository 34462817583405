import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import '../ts/Utility';
import {questionMessage} from '../ts/Message';
import { Auth } from '../ts/Auth';

import img_drone from '../assets/images/menu_drone.svg'
import img_drone_act from '../assets/images/menu_drone_act.svg'
import img_flight_history from '../assets/images/menu_flight_history.svg'
import img_flight_history_act from '../assets/images/menu_flight_history_act.svg'
import img_password from '../assets/images/menu_password.svg'
import img_password_act from '../assets/images/menu_password_act.svg'
import img_company from '../assets/images/menu_company.svg'
import img_company_act from '../assets/images/menu_company_act.svg'
import img_gateway from '../assets/images/menu_gateway.svg'
import img_gateway_act from '../assets/images/menu_gateway_act.svg'
import img_csv from '../assets/images/menu_csv.svg'
import img_csv_act from '../assets/images/menu_csv_act.svg'
import img_logout from '../assets/images/menu_logout.svg'
import img_logout_act from '../assets/images/menu_logout_act.svg'

import { ModalComponent, DialogResult, ModalComponentProps } from './ModalComponent';

export const MainMenuComponent: React.FC<{ active?: string, confirm? : boolean }> = (props) => {
    
    let menu_class: string = 'main-menu-content vstack active-' + props.active + Auth.class_admin;

    const navigate = useNavigate();

    const [modalConfig, setModalConfig] = React.useState<ModalComponentProps | undefined>();
    const modal_dialog = async (title: string, message: string, type?:number) => {
        return await new Promise<number>((resolve) => {
            setModalConfig({
                onClose: (res) => {
                    setModalConfig(undefined);
                    resolve(res);
                },
                title: title,
                message: message,
                type: type
           })
         });
    };

    const navigate_confirm = (e:any) => {
        if(props.confirm) {
            modal_dialog('確認', questionMessage.Q0006).then(result => {
                if(result == DialogResult.Yes) {
                    navigate(e.target.dataset.url);
                }
            })
            e.preventDefault();
        }
        Auth.company_list_query = null;
        Auth.gateway_list_query = null;
        Auth.drone_list_query = null;
    };

    return (
        <>
            <aside className={menu_class}>
                <Link className='nav-link d-flex align-items-center fw-bold menu1' onClick={navigate_confirm} data-url='/drone/list' to='/drone/list'>
                    <img src={img_drone} alt='' className='deact'/>
                    <img src={img_drone_act} alt='' className='act'/>
                    ドローン一覧</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu2' onClick={navigate_confirm} data-url='/history/download' to='/history/download'>
                    <img src={img_flight_history} alt='' className='deact'/>
                    <img src={img_flight_history_act} alt='' className='act'/>
                    飛行ログダウンロード</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu3' onClick={navigate_confirm} data-url='/password/change' to='/password/change'>
                    <img src={img_password} alt='' className='deact'/>
                    <img src={img_password_act} alt='' className='act'/>
                    パスワード変更</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu4' onClick={navigate_confirm} data-url='/company/list' to='/company/list'>
                    <img src={img_company} alt='' className='deact'/>
                    <img src={img_company_act} alt='' className='act'/>
                    企業一覧</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu5' onClick={navigate_confirm} data-url='/gateway/list' to='/gateway/list'>
                    <img src={img_gateway} alt='' className='deact'/>
                    <img src={img_gateway_act} alt='' className='act'/>
                    ゲートウェイ一覧</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu6' onClick={navigate_confirm} data-url='/company/csv' to='/company/csv'>
                    <img src={img_csv} alt='' className='deact'/>
                    <img src={img_csv_act} alt='' className='act'/>
                    企業一括登録</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu7' onClick={navigate_confirm} data-url='/gateway/csv' to='/gateway/csv'>
                    <img src={img_csv} alt='' className='deact'/>
                    <img src={img_csv_act} alt='' className='act'/>
                    ゲートウェイ一括登録</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu8' onClick={navigate_confirm} data-url='/drone/csv' to='/drone/csv'>
                    <img src={img_csv} alt='' className='deact'/>
                    <img src={img_csv_act} alt='' className='act'/>
                    ドローン一括登録</Link>
                <Link className='nav-link d-flex align-items-center fw-bold menu9' onClick={navigate_confirm} data-url='/logout' to='/logout'>
                   <img src={img_logout} alt='' className='deact'/>
                    <img src={img_logout_act} alt='' className='act'/>
                    ログアウト</Link>
            </aside>

            {modalConfig && <ModalComponent {...modalConfig} />}

        </>
    );

}