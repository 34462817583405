import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import '../ts/Utility';
import { fetch_ex } from '../ts/Utility';
import {errorMessage, infoMessage, questionMessage} from '../ts/Message';
import { DialogResult, DialogType, ModalComponent, ModalComponentProps } from './ModalComponent';
import { Auth } from '../ts/Auth';

export const GatewayRegisterComponent: React.FC = () => {
    const navigate = useNavigate();

    const [company_name, setCompanyName] = React.useState<string>('');
    const [company_id, setCompanyId] = React.useState<string>('');
    const [gateway_id, setGatewayId] = React.useState<string>('');
    const [latitude, setLatitude] = React.useState<string>('');
    const [longitude, setLongitude] = React.useState<string>('');
    const [is_enabled, setIsEnabled] = React.useState('');
    const [remarks, setRemarks] = React.useState('');

    const handle_dropdown_company_click = (event:any) => {
        setCompanyId(event.target.dataset.value);
        setCompanyName(event.target.dataset.text);
        event.preventDefault();
    };
    const handle_gateway_id_change = (event:any) => setGatewayId(event.target.value);
    const handle_latitude_change = (event:any) => setLatitude(decimal_only_input(event.target.value));
    const handle_longitude_change = (event:any) => setLongitude(decimal_only_input(event.target.value));
    const handle_isenabled_change = (event:any) => setIsEnabled(event.target.value);
    const handle_remarks_change = (event:any) => setRemarks(event.target.value);

    const decimal_only_input = (text:string) => {
        return text.replace(/[^0-9\.\-]/g, '').replace(/(?<=\.[^\.]+)\./g ,'');
    };

    const [company_list, setCompanyList] = React.useState<any[]>([]);

    const [title , setTitle ] = React.useState('');
    const [message , setMessage ] = React.useState('');

    const [isEdit , setIsEdit ] = React.useState(false);

    const searchQuery = useLocation().search;
    const queryParameter = new URLSearchParams(searchQuery);

    const [processing, setProcessing] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [modalConfig, setModalConfig] = React.useState<ModalComponentProps | undefined>();
    const modal_dialog = async (title: string, message: string, type?:number) => {
        return await new Promise<number>((resolve) => {
            setModalConfig({
                onClose: (res) => {
                    setModalConfig(undefined);
                    resolve(res);
                },
                title: title,
                message: message,
                type: type
           })
         });
    };

    React.useEffect(() => { initialize(); }, []);

    const initialize = () => {
        if(Auth.is_admin === false) {
            navigate('/');
            return;
        }

        init_company_list();
        if(queryParameter.has('company_id') && queryParameter.has('gateway_id')) {
            setTitle('ゲートウェイ編集');
            initialize_edit(queryParameter.get('company_id')!, queryParameter.get('gateway_id')!);
        } else {
            setTitle('ゲートウェイ新規登録');
            setIsLoading(false);
        }
    };

    const init_company_list = () => {
        const api: string = '/company/list';
        const method: string = 'GET';
        let query:any = {};

        fetch_ex(api , method, query, true).then(data => {
            if(data.status !== 200) {
                setMessage(errorMessage.E0018);
                return;
            }
            setCompanyList(data.result.company_list);
        });
    };


    const initialize_edit = (company_id: string, gateway_id: string) => {
        setCompanyId(company_id);
        setGatewayId(gateway_id);
        setIsEdit(true);

        // API呼び出し
        const api: string = '/gateway';
        const method = 'GET';
        const param = {'company_id': company_id, 'gateway_id' : gateway_id };

        fetch_ex(api , method, param, true).then(data => {
            if (data.status === 200) {
                // ステータス：200（成功）の場合
                setCompanyName(data.result.company_name);
                setLatitude(data.result.latitude);
                setLongitude(data.result.longitude);
                setIsEnabled(data.result.is_enabled);
                setRemarks(data.result.remarks);
            } else {
                setMessage(errorMessage.E0018);
            }
            setIsLoading(false);
        });
    };


    const handle_back = () => navigate('/gateway/list');

    const handle_overwrite = () => {
        // 入力チェック呼び出し
        const checkResult = validate_input_overwrite();
        if (!checkResult) {return false;}

        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0004).then(result => {
            if(result != DialogResult.Yes) {
                return;
            }

            setProcessing(true);

            // API呼び出し
            const api: string = '/gateway';
            const method = 'PUT';
            let param:any = {
                'company_id': company_id,
                'gateway_id' : gateway_id,
                'latitude' : latitude,
                'longitude' : longitude,
                'is_enabled' : is_enabled, 
                'remarks' : remarks
            };

            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);

                if (data.status === 200) {
                    // ステータス：200（成功）の場合
                    modal_dialog('完了', 'ゲートウェイ情報' + infoMessage.I0004, DialogType.Information).then(result => {
                        navigate('/gateway/list');
                    });
                } else {
                    setMessage(errorMessage.E0018);
                }
            });
        });
            
    };

    const handle_delete = () => {
        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0005).then(result => {
            if(result != DialogResult.Yes) {
                return;
            }

            setProcessing(true);

            // API呼び出し
            const api: string = '/gateway';
            const method = 'DELETE';
            const param = {
                'company_id': company_id,
                'gateway_id': gateway_id,
            };
            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);

                if (data.status === 200) {
                    // ステータス：200（成功）の場合
                    modal_dialog('完了', 'ゲートウェイ情報' + infoMessage.I0005, DialogType.Information).then(result => {
                        navigate('/gateway/list');
                    });
                } else {
                    setMessage(errorMessage.E0018);
                }
            });
        });
    };

    const handle_register = () => {
        // 入力チェック呼び出し
        const checkResult = validate_input_regist();
        if (!checkResult) {return false;}

        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0003).then(result => {
            if(result != DialogResult.Yes) {
                return;
            }

            setProcessing(true);

            // API呼び出し
            const api: string = '/gateway';
            const method = 'POST';
            const param = {
                'company_id': company_id, 
                'gateway_id': gateway_id, 
                'latitude': latitude, 
                'longitude': longitude, 
                'is_enabled' : is_enabled, 
                'remarks' : remarks
            };
            fetch_ex(api , method, param, true).then(data => {
                setProcessing(false);
                
                if (data.status === 200) {
                    // ステータス：200（成功）の場合
                    modal_dialog('完了', 'ゲートウェイ情報' + infoMessage.I0003, DialogType.Information).then(result => {
                        navigate('/gateway/list');
                    });
                } else if (data.status === 402) {
                    // ステータス：402（ゲートウェイID重複）の場合
                    setMessage(errorMessage.E0006);
                } else {
                    setMessage(errorMessage.E0018);
                }
            });
        });

    }

    const validate_input_regist = () => {
        let msg: string = '';
        let valid = true;
        let reg_lat = /^-?[0-9]{1,2}(\.[0-9]{1,7})?$/;
        let reg_lng = /^-?[0-9]{1,3}(\.[0-9]{1,7})?$/;

        if (company_id === '') {
            msg += '企業名' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (gateway_id === '') {
            msg += 'ゲートウェイID' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (latitude === '') {
            msg += '緯度' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        } else if(reg_lat.test(latitude) === false) {
            msg += errorMessage.E0012.replace('{0}', '緯度').replace('{1}', '2');
            msg += '\n';
            valid = false;
        }
        if (longitude === '') {
            msg += '経度' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        } else if(reg_lng.test(longitude) === false) {
            msg += errorMessage.E0012.replace('{0}', '経度').replace('{1}', '3');
            msg += '\n';
            valid = false;
        }
        if (is_enabled === '') {
            msg += '使用区分' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }

        msg.trimEnd();
        setMessage(msg);
        return valid;
    };

    const validate_input_overwrite = () => {
        return validate_input_regist();
    };

    return (
        <>
            <main className={'gateway-register' + Auth.class_admin}>
                <label className='h5 fw-bold mb-4'>{title}</label>
                <hr/>
 
                <div className={ 'd-inline-block h3 position-absolute ' + (isLoading ? '' : 'd-none')}>
                    <div className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>ロード中...</span>
                    </div>
                </div>

                <fieldset className={ isLoading ? 'invisible' : 'visible'}>
                    <label className='required my-2'>＊は必須項目です</label>
                    <p>
                        <label>企業名</label><label className='required'>＊</label>
                        <div className='dropdown dropdown-company'>
                            <button className='btn border dropdown-toggle' type='button' id='dropdownCompany' data-bs-toggle='dropdown' aria-expanded='false' disabled={isEdit}>
                                <label data-value={company_id}>{company_id == '' ? '' : company_id + '-' + company_name}</label>
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownCompany'>
                                <li><a className='dropdown-item' href='.' onClick={handle_dropdown_company_click} data-value='' >&nbsp;</a></li>
                                { company_list.map((item) => {
                                    return(
                                        <li><a className='dropdown-item' href='.' onClick={handle_dropdown_company_click} data-value={item.company_id} data-text={item.company_name}>{item.company_id + '-' + item.company_name}</a></li>
                                    )
                                }) }
                            </ul>
                        </div>
                    </p>
                    <p>
                        <label>ゲートウェイID</label><label className='required'>＊</label>
                        <input className='form-control' type='text' readOnly={isEdit} value={gateway_id} onChange={handle_gateway_id_change}/>
                    </p>
                    <p>
                        <label>緯度</label><label className='required'>＊</label>
                        <input className='form-control' type='text' value={latitude} onChange={handle_latitude_change} placeholder='99.9999999'/>
                    </p>
                    <p>
                        <label>経度</label><label className='required'>＊</label>
                        <input className='form-control' type='text' value={longitude} onChange={handle_longitude_change} placeholder='999.9999999'/>
                    </p>
                    <p>
                        <label>使用区分</label><label className='required me-4'>＊</label>
                        <label className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' name='is_enabled' value='1' checked={ is_enabled == '1'} onChange={handle_isenabled_change}/>使用
                        </label>
                        <label className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' name='is_enabled' value='0' checked={ is_enabled == '0'} onChange={handle_isenabled_change}/>停止
                         </label>
                    </p>
                    <p>
                        <label>備考</label>
                        <textarea className='form-control' value={remarks} onChange={handle_remarks_change} />
                    </p>

                </fieldset>

                <hr />

                <div className='hstack'>
                    <button className={ 'me-4 btn btn-accept' + (isEdit ? ' d-none' : '') } onClick={handle_register} disabled={processing || isLoading}>登　録</button>
                    <button className={ 'me-4 btn btn-accept' + (isEdit ? '' : ' d-none') } onClick={handle_overwrite} disabled={processing || isLoading}>上書き</button>
                    <button className={ 'me-4 btn btn-delete' + (isEdit ? '' : ' d-none') } onClick={handle_delete} disabled={processing || isLoading}>削　除</button>
                    <button className='me-4 btn btn-back' onClick={handle_back} disabled={processing || isLoading}>戻　る</button>
                </div>
                <p className='error-message'>{message}</p>


            </main>

            {modalConfig && <ModalComponent {...modalConfig} />}
       </>
    );
}
