import React from 'react';
import { useNavigate } from 'react-router-dom'
import '../ts/Utility';
import { fetch_ex, parseISO8601, trim_cst } from '../ts/Utility';
import { errorMessage, infoMessage} from '../ts/Message';

import { Auth } from '../ts/Auth';

import img_search from '../assets/images/search.svg';
import img_new from '../assets/images/new.svg';

import img_activity_on from '../assets/images/activity_on.svg';
import img_activity_off from '../assets/images/activity_off.svg';

export const GatewayListComponent: React.FC = () => {

    const navigate = useNavigate();

    const [company_name, setCompanyName] = React.useState<string>('');
    const [company_id, setCompanyId] = React.useState<string>('');
    const [company_list, setCompanyList] = React.useState<any[]>([]);

    const [gateway_id, setGatewayId] = React.useState<string>('');

    const [message , setMessage ] = React.useState('');
    const [prevSortKey, setPrevSortKey ] = React.useState('');
    const [totalKensu , setTotalKensu ] = React.useState(Number);
    const [fromKensu , setFromKensu ] = React.useState(Number);
    const [toKensu , setToKensu ] = React.useState(Number);
    
    const [rowData , setData ] = React.useState({ data:[]});
    const [pagenation , setPagenation ] = React.useState({ current: 1, pages: [1], max_page: 1 });
    const [tableDisplayFlg , setTableDisplayFlg ] = React.useState(false);

    const [processing, setProcessing] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => { 
        if(Auth.is_admin) {
            init_company_list();
        } else {
            setCompanyList([{'company_name':Auth.company_name, 'company_id' : Auth.company_id}]);
            setCompanyName(Auth.company_name!);
            setCompanyId(Auth.company_id!);
        }

        if(Auth.gateway_list_query != null) {
            if(Auth.gateway_list_query.company_id) {
                setCompanyId(Auth.gateway_list_query.company_id);
                setCompanyName(Auth.gateway_list_query._company_name);
            }
            if(Auth.gateway_list_query.gateway_id) {
                setGatewayId(Auth.gateway_list_query.gateway_id);
            }
        }

        if(Auth.is_admin) {
            gatewayListSearch();
        } else {
            let append_query: any = { 
                company_id : Auth.company_id,
                _company_name : Auth.company_name,
            };
            gatewayListSearch(append_query);
        }
    }, []);

    const init_company_list = () => {
        const api: string = '/company/list';
        const method: string = 'GET';
        let query:any = {};

        fetch_ex(api , method, query, true).then(data => {
            if(data.status !== 200) {
                setMessage(errorMessage.E0018);
                return;
            }
            setCompanyList(data.result.company_list);
        });
    };

    const pageSize = 20;

    const default_query = {
        'page' : 1,
        'page_size' : pageSize
    };

    const set_query = (base_query: any, key: any, value: any) => {
        if(value && value != null) {
            base_query[key] = value;
        } else {
            delete base_query[key];
        }
    }

    const rebuild_query = (base_query: any) => {
        for(let key in base_query) {
            if(key.startsWith('_')) {
                delete base_query[key];
            }
        }
    }

    const handle_search = () => {
        let append_query: any = { 
            page : 1 ,
            company_id : company_id != '' ? company_id : null,
            _company_name : company_name,
            gateway_id : gateway_id != '' ? gateway_id : null,
        };

        gatewayListSearch(append_query);

    };

    const handle_keydown = (event:any) => {
        if(event.key === 'Enter') {
            handle_search();
        }
    };

    const handle_dropdown_company_click = (event:any) => {
        setCompanyId(event.target.dataset.value);
        setCompanyName(event.target.dataset.text);
        event.preventDefault();
    };

    const handle_gateway_id_change = (event:any) => {
        setGatewayId(event.target.value);
    };


    function toGatewayRegister(){
        // ゲートウェイ登録画面へ遷移
        navigate('/gateway/regist');
    }

    const toGatewayEdit = (event:any) => {
        const company_id = event.target.dataset.company;
        const gateway_id = event.target.dataset.gateway;
        navigate('/gateway/regist?company_id=' + encodeURIComponent(company_id) + '&gateway_id=' + encodeURIComponent(gateway_id));
        event.preventDefault();
    };

    const set_order = (event:any) => {
        if(prevSortKey == event.target.dataset.sortkey) {
            gatewayListSearch({'sort_key' : event.target.dataset.sortkey, 'sort_order' : 'DESC', 'page' : 1 });
            setPrevSortKey('');
        } else {
            gatewayListSearch({'sort_key' : event.target.dataset.sortkey , 'sort_order' : 'ASC', 'page' : 1 });
            setPrevSortKey(event.target.dataset.sortkey);
        }
        event.preventDefault();
    };

    const set_page = (event:any) => {
        gatewayListSearch({'page' : event.target.dataset.page});
        event.preventDefault();
    };


    function gatewayListSearch(append_query?: any) {
        setProcessing(true);

        // API呼び出し
        const api: string = '/gateway/search';
        const method: string = 'POST';

        let query:any = Auth.gateway_list_query == null ? default_query : Auth.gateway_list_query;

        if(append_query) {
            for(let key in append_query) {
                set_query(query, key, append_query[key]);
            }
        }

        Auth.gateway_list_query = query;

        rebuild_query(query);
        fetch_ex(api , method, query, true).then(data => {
            setProcessing(false);
            setIsLoading(false);

            if(data.status !== 200) {
                setMessage(errorMessage.E0018);
                return;
            }

            let rows:any = { };
            rows.data = [];
            for (let i in data.result.gateway_list) {
                let item = data.result.gateway_list[i];
                rows.data.push({
                    activity : item.activity,
                    company_id : item.company_id,
                    company_name : item.company_name,
                    gateway_id : item.gateway_id,
                    latitude : item.latitude,
                    longitude : item.longitude,
                    is_enabled : item.is_enabled,
                    remarks : item.remarks,
                    created_at : item.created_at,
                    modified_at : item.modified_at
                });
            }

            setFromKensu(pageSize * (data.result.page - 1) + 1);
            setToKensu(pageSize * (data.result.page - 1) + data.result.gateway_list.length);
            setTotalKensu(data.result.count);
            setData(rows);
            let pages: number[] = [];

            const page_span = 2;
            let start_page = data.result.page - page_span;
            let end_page = data.result.page + page_span;
            if(start_page < 1) {
                end_page = end_page - start_page + 1;
                start_page = 1;
            } else if(end_page > data.result.max_page) {
                start_page = start_page + data.result.max_page - end_page;
                end_page = data.result.max_page;
            }
            if(start_page < 1) {
                start_page = 1;
            } else if(end_page > data.result.max_page) {
                end_page = data.result.max_page;
            }
            
            for(let i = start_page; i <= end_page; i++) {
                pages.push(i);
            }

            setPagenation({
                current: data.result.page,
                pages : pages,
                max_page: data.result.max_page,
            });
            
            if (data.result.count === 0) {
                // 検索結果0件
                setMessage(infoMessage.I0006);
                setTableDisplayFlg(false);
            } else {
                setTableDisplayFlg(true);
                setMessage('');
            }
        });

    }

    return (
        <>
            <main className={'gateway-list' + Auth.class_admin}>
                <label className='h5 fw-bold mb-4'>ゲートウェイ一覧</label>
                <hr/>

                <fieldset className='hstack'>
                    <p className='me-3'>
                        <label>企業名</label>
                        <div className='dropdown dropdown-company'>
                            <button className='btn border dropdown-toggle' type='button' id='dropdownCompany' data-bs-toggle='dropdown' aria-expanded='false' disabled={Auth.is_normal}>
                                <label data-value={company_id}>{company_id == '' ? '' : company_id + '-' + company_name}</label>
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownCompany'>
                                <li><a className='dropdown-item' href='.' onClick={handle_dropdown_company_click} data-value='' data-text=''>&nbsp;</a></li>
                                { company_list.map((item) => {
                                    return(
                                        <li><a className='dropdown-item' href='.' onClick={handle_dropdown_company_click} data-value={item.company_id} data-text={item.company_name}>{item.company_id + '-' + item.company_name}</a></li>
                                    )
                                }) }
                            </ul>
                        </div>
                    </p>
                    <p className='me-3'>
                        <label>ゲートウェイID（部分一致）</label>
                        <input className='form-control' type='text' onKeyDown={handle_keydown} value={gateway_id} onChange={handle_gateway_id_change} />
                    </p>
                    <button className='btn btn-search' onClick={handle_search} disabled={processing}><img src={img_search} alt='' />検　索</button>
                </fieldset>

                <hr/>

                <div className='hstack mb-3 align-items-end'>
                    <p className='error-message'>{message}</p>
                    <div className={tableDisplayFlg ? '' : 'd-none'}>全 {totalKensu} 件中　{fromKensu} 件 ～ {toKensu} 件 を表示</div>
                    <button className='regist-button ms-auto btn btn-accept' onClick={toGatewayRegister}><img src={img_new} alt='' />新規登録</button>
                </div>

                <table className={tableDisplayFlg ? 'table radius-table' : 'd-none'}>
                    <thead>
                        <tr>
                        <th><a data-sortkey='activity' href='.' onClick={set_order}>通信</a></th>
                        <th><a data-sortkey='company_id' href='.' onClick={set_order}>企業ID</a></th>
                        <th><a data-sortkey='company_name' href='.' onClick={set_order}>企業名</a></th>
                        <th><a data-sortkey='gateway_id' href='.' onClick={set_order}>ゲートウェイID</a></th>
                        <th><a data-sortkey='latitude' href='.' onClick={set_order}>緯度</a></th>
                        <th><a data-sortkey='longitude' href='.' onClick={set_order}>経度</a></th>
                        <th className='text-center'><a data-sortkey='is_enabled' href='.' onClick={set_order}>使用区分</a></th>
                        <th><a data-sortkey='remarks' href='.' onClick={set_order}>備考</a></th>
                        <th><a data-sortkey='created_at' href='.' onClick={set_order}>登録日時</a></th>
                        <th><a data-sortkey='modified_at' href='.' onClick={set_order}>更新日時</a></th>
                        </tr>
                    </thead>
                    <tbody className='detail-body'>
                        {rowData.data.map((row: any) => {
                            return (
                                <tr>
                                <td><img src={row.activity == '1' ? img_activity_on: img_activity_off } alt='' /></td>
                                <td><label>{row.company_id}</label></td>
                                <td><label>{row.company_name}</label></td>
                                <td>
                                    <a className='fw-bold gateway_link' href='.' data-company={row.company_id} data-gateway={row.gateway_id} onClick={toGatewayEdit}>{row.gateway_id}</a>
                                    <label className='gateway_label'>{row.gateway_id}</label>
                                </td>
                                <td><label>{row.latitude}</label></td>
                                <td><label>{row.longitude}</label></td>
                                <td className='text-center'><label>{row.is_enabled == Auth.ENABLE ? '使用' : '停止'}</label></td>
                                <td><label>{trim_cst(row.remarks)}</label></td>
                                <td><label>{parseISO8601(row.created_at)}</label></td>
                                <td><label>{parseISO8601(row.modified_at)}</label></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <nav aria-label='...' className={ pagenation.max_page <= 1 ? 'd-none' :'' }>
                    <ul className='pagination'>
                        <li className='page-item'>
                            <a className='page-link' href='.' data-page={1} onClick={set_page}>≪</a>
                        </li>

                        {pagenation.pages.map((page: number) => {
                            if(page == pagenation.current) {
                                return (
                                    <li className='page-item active' aria-current='page'>
                                        <span className='page-link'>{page}</span>
                                    </li>
                                );
    
                            } else {
                                return (
                                    <li className='page-item'><a className='page-link' href='.' data-page={page} onClick={set_page}>{page}</a></li>
                                );
                            }
                        })}

                        <li className='page-item'>
                            <a className='page-link' href='.' data-page={pagenation.max_page} onClick={set_page}>≫</a>
                        </li>
                    </ul>
                </nav>

                <div className={ 'd-flex align-items-center justify-content-center' + (isLoading ? '' : ' d-none') }>
                    <div className='d-inline-block h3'>
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>ロード中...</span>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}

