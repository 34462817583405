import React from 'react';
import '../ts/Utility';
import { fetch_ex, fetch_ex_put_file } from '../ts/Utility';
import {errorMessage, infoMessage, questionMessage} from '../ts/Message';
import { DialogResult, DialogType, ModalComponent, ModalComponentProps } from './ModalComponent';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../ts/Auth';

export const CompanyBulkRegisterComponent: React.FC = () => {
    const navigate = useNavigate();
    
    const download_anchor = React.useRef<HTMLAnchorElement>(null);
    const [download_url , setDownloadUrl] = React.useState('');
    const [message , setMessage] = React.useState('');
    const [file, setFile] = React.useState<File|null>(null);
    const file_ref = React.useRef<HTMLInputElement>(null);

    const [processing, setProcessing] = React.useState(false);

    const [modalConfig, setModalConfig] = React.useState<ModalComponentProps | undefined>();
    const modal_dialog = async (title: string, message: string, type?:number) => {
        return await new Promise<number>((resolve) => {
            setModalConfig({
                onClose: (res) => {
                    setModalConfig(undefined);
                    resolve(res);
                },
                title: title,
                message: message,
                type: type
           })
         });
    };

    const handle_download = () => {
        // API呼び出し
        const api: string = '/company/csv/export';
        const method = 'POST';
        const param = { };
        setProcessing(true);

        fetch_ex(api , method, param, true).then(data => {
            if(data.timeout) {
                setMessage(errorMessage.E0013);
            } else if (data.status === 200) {
                // ステータス：200（成功）の場合
                setDownloadUrl(data.result.presigned_url);
                download_anchor.current!.click();
            } else {
                setMessage(errorMessage.E0018);
            }
            setProcessing(false);
        });
    };

    const handle_change = (event:any) => setFile(event.target.files[0]);

    const handle_upload = async () => {
        setMessage('');
        if (!validate()) {return false;}
        
        // ポップアップ呼び出し
        modal_dialog('確認', questionMessage.Q0002).then(result => {
            if(result !== DialogResult.Yes) {
                return;
            }

            setProcessing(true);
            upload();
        });

    };

    const upload = async () => {
        // アップロード先取得
        const api: string = '/s3/presigned_url';
        const method = 'GET';
        const param = { 'filename' : 'upload_company.csv' };
        const data = await fetch_ex(api , method, param, true);
        if (data.status !== 200) {
            setMessage(errorMessage.E0018);
            setProcessing(false);
            return;
        }
        let presigned_url = data.result.presigned_url;

        // アップロード
        const response = await fetch_ex_put_file(presigned_url, file!);
        if(response.status !== 200) {
            file_ref.current!.value = '';
            setFile(null);
            setMessage(errorMessage.E0016);
            setProcessing(false);
            return;
        }

        // CSV取り込み
        const api2 = '/company/csv/import';
        const method2 = 'POST';
        const param2 = { 'presigned_url' : presigned_url };
        const data2 = await fetch_ex(api2, method2, param2, true);

        setProcessing(false)
        file_ref.current!.value = '';
        setFile(null);

        if(data2.timeout) {
            setMessage(errorMessage.E0013);
        } else if(data2.status === 200) {
            modal_dialog('完了', infoMessage.I0002, DialogType.Information).then(()=>{});
        } else if(data2.status === 401) {
            setMessage(errorMessage.E0006);
        } else if(data2.status === 402) {
            setMessage(errorMessage.E0004);
        } else if(data2.status === 403) {
            setMessage(errorMessage.E0008);
        } else if(data2.status === 404) {
            setMessage(errorMessage.E0017);
        } else {
            setMessage(errorMessage.E0018);
        }

    };

    const validate = () => {

        let msg: string = '';
        let valid = true;

        if (file === null) {
            msg += errorMessage.E0005;
            msg += '\n';
            valid = false; 
        }

        msg.trimEnd();
        setMessage(msg);
        return valid;
    };

    React.useEffect(() => { initialize(); }, []);
    const initialize = () => {
        if(Auth.is_admin === false) {
            navigate('/');
            return;
        }
    }

    return (
        <main className='company-bulk-register'>
            <label className='h5 fw-bold mb-4'>企業一括登録</label>
            <hr/>
            <div className='h5 fw-bold my-4'>IMPORT</div>
            <div>
                <div className='mb-2'>・取込ファイルはCSV形式を指定してください</div>
                <div className='mb-2'>・項目間の区切り文字はカンマとしてください（タブは不可）</div>
                <div className='mb-2'>・ダブルクォーテーション(&quot;)は使用しないでください</div>
                <div className='mb-2'>・文字コードはUTF-8形式を使用してください</div>
                <div className='mb-2'>・取込データはヘッダー不要です</div>
                <div className='mb-2'>・追記形式でDB取込を行います（洗い替えではありません）</div>
            </div>
            <div className='my-4'>
                インポートデータ
                <div className='hstack'>
                    <input className='form-control w-50' type='file' onChange={handle_change} disabled={processing} ref={file_ref}/>
                    <button className='btn btn-accept ms-3' onClick={handle_upload} disabled={processing}>取　込</button>
                </div>
            </div>
            <div className='error-message'>{message}</div>

            <hr/>
            <div className='h5 fw-bold my-4'>EXPORT</div>
            <button className='btn btn-accept my-3' onClick={handle_download} disabled={processing}>ダウンロード</button>
            <a className='invisible' ref={download_anchor} href={download_url} download={download_url}>download</a>

            <hr/>

            {modalConfig && <ModalComponent {...modalConfig} />}

            <div className={ 'h3 ' + (processing ? '' : 'd-none')}>
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>処理中...</span>
                </div>
            </div>

        </main>
    );
}
