import React from 'react';
import { useNavigate } from 'react-router-dom'
import '../ts/Utility';
import { fetch_ex } from '../ts/Utility';
import {errorMessage} from '../ts/Message';

import img_login from '../assets/images/login.svg'
import img_pc from '../assets/images/pc.svg'
import img_config from '../assets/images/config.svg'

export const LoginPageComponent: React.FC = () => {
    
    const navigate = useNavigate();
    const companyId = React.useRef<HTMLInputElement>(null);
    const password = React.useRef<HTMLInputElement>(null);

    const [message , setMessage ] = React.useState('');

    const [processing, setProcessing] = React.useState(false);

    function login() {
        // 入力チェック呼び出し
        let checkResult = validate_login();
        if (!checkResult) {return false;}

        setProcessing(true);

        // API呼び出し
        const api: string = '/auth/login';
        const method = 'POST';
        const param = {'id': companyId.current!.value, 'password' : password.current!.value, 'type' : 1};
        fetch_ex(api , method, param, false).then(data => {
            setProcessing(false);

            if (data.status === 200) {
                // ステータス：200（ログイン成功）の場合、セッションストレージに値格納
                sessionStorage.setItem('drone_access_token', data.result.access_token);
                sessionStorage.setItem('drone_refresh_token', data.result.refresh_token);
                sessionStorage.setItem('drone_user_id', data.result.user.id);
                sessionStorage.setItem('drone_user_name', data.result.user.name);
                sessionStorage.setItem('drone_user_authority', data.result.user.authority);

                navigate('/drone/list');
            } else if(data.status === 401) {
                // ステータス：401（ログイン失敗）の場合、エラーメッセージ出力
                setMessage(errorMessage.E0002);
            } else {
                setMessage(errorMessage.E0018);
            }
        });

    }

    function validate_login() {
        let msg: string = '';
        let valid = true;
        if (!companyId.current!.value) {
            msg += '企業ID' + errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        if (!password.current!.value) {
            msg += 'パスワード' +  errorMessage.E0001;
            msg += '\n';
            valid = false;
        }
        msg.trimEnd();
        setMessage(msg);
        return valid;
    }

    const handle_keydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter') {
            login();
        }
    };

    return (
        <>
            <div className='line' />
            <main>
                <div className='login-title d-flex align-items-center'><img src={img_login} alt='' />ログイン</div>
                <div>企業ID／パスワードを入力し、「ログイン」ボタンを押下してください。</div>
                <div className='login-content-box d-flex align-items-center justify-content-center'>
                    <div className='container login-content'>
                        <div className='row g-3'>
                            <div className='col-md-auto w-25 d-flex align-items-center'>企業ID</div>
                            <div className='col'><input className='form-control my-1' type='text' ref={companyId} onKeyDown={handle_keydown} /></div>
                        </div>
                        <div className='row g-3'>
                            <div className='col-md-auto w-25 d-flex align-items-center'>パスワード</div>
                            <div className='col'><input className='form-control my-1' type='password' ref={password} onKeyDown={handle_keydown} /></div>
                        </div>
                        <div className='row g-3'>
                            <div className='col'><button className='btn btn-accept w-100 mt-4' onClick={login} disabled={processing}>ログイン</button></div>
                        </div>
                        <div className='row'>
                            <div className='error-message'>{message}</div>
                        </div>
                    </div>
                </div>
            </main>

            <div className='login-description'>
                <div className='line' />

                <div className='title my-3'>推奨環境について</div>

                <div className='fw-bold mt-3'><img src={img_pc} alt='' className='me-2' />推奨ブラウザ</div>
                <div className='mt-1'>本サイトでは以下のブラウザを推奨いたします。推奨以外のブラウザをご利用になる場合は、表示等を含め不具合が発生する場合がありますので、予めご了承ください。</div>
                <div className='mt-1'>PC：Google Chrome</div>
                
                <div className='fw-bold mt-3'><img src={img_config} alt='' className='me-2' />ブラウザの設定</div>
                <div className='mt-1'>JavaScriptおよびCookieは利用できる設定にしてください。</div>
            </div>
        </>
    );
}