
// 種類：エラー
export namespace errorMessage { 
    export const E0001: string = 'を入力してください。';
    export const E0002: string = '企業ID、またはパスワードが間違っています。';
    export const E0003: string = '現在のパスワードが登録されているパスワードと一致しません。';
    export const E0004: string = 'パスワードがポリシーに準拠していません。\n以下のポリシーに準拠する必要があります。\n・半角英数字\n・8文字以上';
    export const E0005: string = '指定されたファイルが存在しません。';
    export const E0006: string = 'すでに登録されているデータが存在するため登録できません。';
    export const E0007: string = '新しいパスワードが確認用パスワードと一致しません。';
    export const E0008: string = 'CSVファイルの必須項目に値が設定されていない箇所があります。';
    export const E0009: string = '飛行日時の日付はカレンダーから選択してください。';
    export const E0010: string = '飛行日時は本日より１年以内になるよう指定してください。';
    export const E0011: string = '飛行日時の日付関係が逆転しています。';
    export const E0012: string = '{0}は整数部最大{1}桁、小数部最大7桁で入力してください。';
    export const E0013: string = '要求がタイムアウトしました。';
    export const E0014: string = '抽出データ件数が多すぎます。\n抽出条件を変更してください。\n（該当件数：{0}件）';
    export const E0015: string = 'CSVファイルで指定された企業は存在しません。';
    export const E0016: string = 'ファイルをアップロードできませんでした。\nファイル選択後に編集はできません。\n再度、ファイルを選択してください。';
    export const E0017: string = 'ファイルの内容に不備があります。\n画面上部の内容を確認してください。';
    export const E0018: string = '未定義のエラーが発生しました。\nシステム担当者にご連絡ください。';
}


// 種類：質問
export namespace questionMessage {
    export const Q0001: string = 'パスワードを変更してもよろしいですか？';
    export const Q0002: string = 'データの取込を実行してもよろしいですか？';
    export const Q0003: string = '登録してもよろしいですか？';
    export const Q0004: string = '更新してもよろしいですか？';
    export const Q0005: string = '削除してもよろしいですか？';
    export const Q0006: string = '入力中の内容は破棄されますが、よろしいですか？';
}

// 種類：情報
export namespace infoMessage {
    export const I0001: string = 'パスワードを変更しました。';
    export const I0002: string = 'データの取込が完了しました。';
    export const I0003: string = 'を登録しました。';
    export const I0004: string = 'を更新しました。';
    export const I0005: string = 'を削除しました。';
    export const I0006: string = 'データが存在しません。';
}
