import React from 'react';
import { Auth } from '../ts/Auth';

export const MainMenuHeaderComponent: React.FC = () => {

  let menu_header_class: string = 'main-menu-header hstack' + Auth.class_admin;

  return (
    <>
      <header className={menu_header_class}>
          <p className='company fw-bold my-0 ms-4 me-auto'>{Auth.company_name}</p>
          <label className='authority mx-4'>管理者権限</label>
      </header>
    </>
  );
}

