import * as React from 'react';
import { LoginPageHeaderComponent } from '../components/LoginPageHeaderComponent'
import { LoginPageComponent } from '../components/LoginPageComponent'
import { LoginPageFooterComponent } from '../components/LoginPageFooterComponent'

import '../index.css';

export const LoginPage: React.FC = () => {
  return (
    <>
      <div className='vstack'>
        <header className='login-header mx-auto'>
          <LoginPageHeaderComponent />
        </header>
        <div className='login-main-content mx-auto'>
          <LoginPageComponent />
        </div>
        <div className='fixed-bottom'>
          <footer className='login-footer d-flex align-items-center justify-content-center'>
            <LoginPageFooterComponent />
          </footer>
        </div>
      </div>
    </>
  );
}
