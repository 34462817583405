import React from 'react';
import img_logo2 from '../assets/images/logo2.png';

export const LogoComponent: React.FC = () => {
  return (
    <>
      <div className='logo-wrap'>
        <div className='d-flex align-items-center justify-content-center logo'><img src={img_logo2} alt='ロゴ' /></div>
      </div>
    </>
  );
}

