import React from 'react';
import { Modal } from 'react-bootstrap';
import '../ts/Utility';

export namespace DialogResult {
    export const No: number = 0;
    export const Yes: number = 1;
    export const OK: number = 2;
}
export namespace DialogType {
    export const Confirm: number = 1;
    export const Information: number = 2;
    export const Error: number = 3;
}

export type ModalComponentProps = {
    onClose: (result: number) => void
    title?: string
    message?: string
    type?: number
}

export const ModalComponent: React.FC<ModalComponentProps> = (props) => {
    const type = props.type === undefined ? DialogType.Confirm : props.type;
    const close = (result: number) => {
        props.onClose(result);
    };
    const yes_visible = type === DialogType.Confirm;
    const no_visible = type === DialogType.Confirm;
    const ok_visible = type !== DialogType.Confirm;

    const type_class = 
        type === DialogType.Confirm ? 'dialog dialog-confirm' :
        type === DialogType.Information ? 'dialog dialog-information' :
        type === DialogType.Error ? 'dialog dialog-error' :
        'dialog';

    return (
        <>
            <div className='dialog-wrapper'></div>
            <Modal.Dialog className={type_class}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>

            <Modal.Footer>
                <button className={'btn btn-dialog-yes ' + (yes_visible ? '' : 'd-none')} onClick={() => close(DialogResult.Yes)}>はい</button>
                <button className={'btn btn-dialog-no ' + (no_visible ? '' : 'd-none')} onClick={() => close(DialogResult.No)}>いいえ</button>
                <button className={'btn btn-dialog-ok ' + (ok_visible ? '' : 'd-none')} onClick={() => close(DialogResult.OK)}>OK</button>
            </Modal.Footer>
            </Modal.Dialog>
        </>
    );
}
