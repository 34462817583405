import * as React from 'react';
import { MainMenuHeaderComponent } from '../components/MainMenuHeaderComponent'
import { MainMenuComponent } from '../components/MainMenuComponent'
import { GatewayListComponent } from '../components/GatewayListComponent'

import '../index.css';
import { LoginPageFooterComponent } from '../components/LoginPageFooterComponent';
import { LogoComponent } from '../components/LogoComponent';

export const GatewayListPage: React.FC = () => {
  return (
    <>
      <div className='content-header'>
        <MainMenuHeaderComponent />
      </div>
      <div className='menu'>
        <div>
          <LogoComponent />
        </div>
        <div>
          <MainMenuComponent active='menu5' />
        </div>
        <div className='flex-glow' />
        <footer className='content-footer d-flex align-items-center justify-content-center'>
          <LoginPageFooterComponent />
        </footer>
      </div>
      <div className='content'>
        <GatewayListComponent />
      </div>
    </>
  );
}
