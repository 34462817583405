export const Auth = {
    get ADMIN() {
        return '1';
    } ,
    get ENABLE() {
        return '1';
    } ,
    get company_name() {
        return sessionStorage.getItem('drone_user_name');
    },
    get company_id() {
        return sessionStorage.getItem('drone_user_id');
    },
    get authority() {
        return sessionStorage.getItem('drone_user_authority');
    },
    get is_admin() {
        return this.authority === this.ADMIN;
    },
    get is_normal() {
        return this.authority !== this.ADMIN;
    },
    get is_login() {
        return sessionStorage.getItem('drone_user_id') !== null;
    },
    get class_admin() {
        return this.is_admin ? ' auth-admin' : ' auth-normal';
    },
    set company_list_query(query: any) {
        sessionStorage.setItem('company_list_query', JSON.stringify(query));
    },
    get company_list_query() {
        const json = sessionStorage.getItem('company_list_query');
        let result:any = null;
        if(json) {
            result = JSON.parse(json);
        }
        return result;
    },
    set gateway_list_query(query: any) {
        sessionStorage.setItem('gateway_list_query', JSON.stringify(query));
    },
    get gateway_list_query() {
        const json = sessionStorage.getItem('gateway_list_query');
        let result:any = null;
        if(json) {
            result = JSON.parse(json);
        }
        return result;
    },
    set drone_list_query(query: any) {
        sessionStorage.setItem('drone_list_query', JSON.stringify(query));
    },
    get drone_list_query() {
        const json = sessionStorage.getItem('drone_list_query');
        let result:any = null;
        if(json) {
            result = JSON.parse(json);
        }
        return result;
    }
};
